import React from "react";
import styled from "styled-components";
import {readableColor} from "polished";
import {AnimatedBox, Button} from "../elements";
import {navigate} from 'gatsby'

const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`;

const PButton = styled(Button) <{ color: string }>`
  background: ${props => (props.color === "white" ? "black" : props.color)};
  color: ${props => readableColor(props.color === "white" ? "black" : props.color)};
`;

const Modal = () => (
    <PBox style={{textAlign: "center"}} py={10} px={[6, 6, 8, 10]}>
        <h2>Want to work together?</h2>
        <PButton color="black" py={4} px={8} onClick={() => {
          navigate("/contact")
        }}>
            Let's connect
        </PButton>
    </PBox>
);

export default Modal;
